var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "DebtorProcessingNotes" },
    [
      _c(
        "div",
        { staticClass: "row row--align-center row--justify-between mb-22" },
        [
          _c("label", { staticClass: "fs-16 fw-med" }, [
            _vm._v("General Note")
          ]),
          !_vm.generalNotes.length
            ? _c(
                "base-button",
                {
                  staticClass: "bg-blue fc-white",
                  on: {
                    click: function($event) {
                      _vm.newGeneralNote = true
                    }
                  }
                },
                [_vm._v("\n      New Note\n    ")]
              )
            : _vm._e()
        ],
        1
      ),
      _vm.newGeneralNote
        ? _c(
            "div",
            { staticClass: "column" },
            [
              _c("base-create-processing-note", {
                attrs: {
                  "debtor-id": _vm.debtor.id,
                  "include-debtor": false,
                  "include-status": false,
                  type: "debtor"
                },
                on: {
                  cancel: function($event) {
                    _vm.newGeneralNote = false
                  },
                  created: _vm.getNotes,
                  edited: _vm.getNotes
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("base-table", {
        attrs: {
          "column-headers": ["", "date", "note", "actions"],
          "which-table": "ClientNotes"
        },
        scopedSlots: _vm._u([
          {
            key: "rows",
            fn: function() {
              return _vm._l(_vm.generalNotes, function(note, index) {
                return _c("base-processing-note", {
                  key: "row-" + index,
                  attrs: { note: note, type: "debtor" },
                  on: { deleted: _vm.getNotes, edited: _vm.getNotes }
                })
              })
            },
            proxy: true
          }
        ])
      }),
      _vm._m(0),
      _c("base-table", {
        attrs: {
          "column-headers": ["", "date", "client", "note", "actions"],
          "which-table": "ClientNotes"
        },
        scopedSlots: _vm._u([
          {
            key: "rows",
            fn: function() {
              return _vm._l(_vm.specificNotes, function(note, index) {
                return _c("base-processing-note", {
                  key: "row-" + index,
                  attrs: { "allow-actions": false, note: note, type: "debtor" },
                  on: { deleted: _vm.getNotes, edited: _vm.getNotes }
                })
              })
            },
            proxy: true
          }
        ])
      }),
      _c("base-pagination", {
        staticClass: "mt-22",
        attrs: {
          "item-count": _vm.notesCount,
          page: _vm.notesPage,
          "request-limit": _vm.requestLimit
        },
        on: { paginate: _vm.getSpecificNotes }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "row row--align-center row--justify-between mb-22" },
      [_c("label", { staticClass: "fs-16 fw-med" }, [_vm._v("Specific Notes")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }