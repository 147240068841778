var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "width-100" },
    [
      _c(
        "transition",
        { attrs: { mode: "out-in", name: "Transition__opacity-fade" } },
        [
          !_vm.editing
            ? _c("div", { key: "contact", staticClass: "column" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "row row--align-center mb-8" }, [
                    _c("label", { staticClass: "fs-16 fw-med capitalize" }, [
                      _vm._v(_vm._s(_vm.contactDefault.name))
                    ]),
                    _vm.contactDefault.is_primary
                      ? _c(
                          "label",
                          {
                            staticClass:
                              "UserProfileContacts__flag-lbl fs-12 uppercase bg-green ml-8"
                          },
                          [_vm._v("\n            PRIMARY CONTACT\n          ")]
                        )
                      : _vm._e()
                  ]),
                  _c("div", { staticClass: "row row--justify-end" }, [
                    _c(
                      "button",
                      {
                        staticClass: "fc-light fs-14 mr-25",
                        on: { click: _vm.editContact }
                      },
                      [
                        _c("i", { staticClass: "fa fa-edit fc-light" }),
                        _vm._v("\n            Edit\n          ")
                      ]
                    ),
                    _vm.pagePOV === "client"
                      ? _c(
                          "button",
                          {
                            class: [
                              "fs-14",
                              {
                                "fc-blue":
                                  _vm.contactDefault.send_notifications,
                                "fc-light": !_vm.contactDefault
                                  .send_notifications
                              }
                            ],
                            on: { click: _vm.toggleContactNotifications }
                          },
                          [
                            _c("i", {
                              class: [
                                "fa fa-bell",
                                {
                                  "fc-blue":
                                    _vm.contactDefault.send_notifications,
                                  "fc-light": !_vm.contactDefault
                                    .send_notifications
                                }
                              ]
                            }),
                            _vm._v(
                              "\n            Notifications " +
                                _vm._s(
                                  _vm.contactDefault.send_notifications
                                    ? "On"
                                    : "Off"
                                ) +
                                "\n          "
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                ]),
                _c("div", { staticClass: "column" }, [
                  _c("label", { staticClass: "fc-light fs-14" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.contactDefault.email) +
                        "\n          " +
                        _vm._s(
                          _vm.contactDefault.email && _vm.contactDefault.phone
                            ? "|"
                            : null
                        ) +
                        "\n          " +
                        _vm._s(_vm.contactDefault.phone) +
                        "\n        "
                    )
                  ]),
                  _c("label", { staticClass: "fc-light fs-14" }, [
                    _vm._v(" " + _vm._s(_vm.contactDefault.role))
                  ])
                ])
              ])
            : _c("div", { key: "contactEdit", staticClass: "row" }, [
                _c("div", { staticClass: "column" }, [
                  _c(
                    "div",
                    { staticClass: "row row--wrap" },
                    [
                      _c(
                        "base-input",
                        {
                          staticClass: "mr-6 mb-9",
                          attrs: {
                            instructions:
                              _vm.instructions.SHORTENED_NAME_INSTRUCTION,
                            label: true,
                            "label-for": "name",
                            placeholder: "John Smith",
                            valid: _vm.valid.name
                          },
                          model: {
                            value: _vm.contactToEdit.name,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.contactToEdit,
                                "name",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "contactToEdit.name"
                          }
                        },
                        [_vm._v("\n            Name\n          ")]
                      ),
                      _c(
                        "base-input",
                        {
                          staticClass: "mr-6 mb-9",
                          attrs: {
                            instructions: _vm.instructions.EMAIL_INSTRUCTION,
                            label: true,
                            "label-for": "email",
                            placeholder: "ted@gmail.com",
                            valid: _vm.valid.email
                          },
                          model: {
                            value: _vm.contactToEdit.email,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.contactToEdit,
                                "email",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "contactToEdit.email"
                          }
                        },
                        [_vm._v("\n            Email\n          ")]
                      ),
                      _c(
                        "base-input",
                        {
                          staticClass: "mr-6 mb-9",
                          attrs: {
                            instructions: _vm.valid.phone
                              ? _vm.instructions.PHONE_CONNECTED_INSTRUCTION
                              : _vm.instructions.PHONE_INSTRUCTION,
                            label: true,
                            "label-for": "phone",
                            placeholder: "(213)361-3826",
                            valid: _vm.valid.phone && _vm.connected.phone
                          },
                          model: {
                            value: _vm.contactToEdit.phone,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.contactToEdit,
                                "phone",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "contactToEdit.phone"
                          }
                        },
                        [_vm._v("\n            Phone\n          ")]
                      ),
                      _vm.pagePOV === "debtor"
                        ? _c(
                            "base-input",
                            {
                              staticClass: "mr-6 mb-9",
                              attrs: {
                                instructions: _vm.instructions.ROLE_INSTRUCTION,
                                label: true,
                                "label-for": "role",
                                placeholder: "Supervisor",
                                valid: _vm.valid.role
                              },
                              model: {
                                value: _vm.contactToEdit.role,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.contactToEdit,
                                    "role",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "contactToEdit.role"
                              }
                            },
                            [_vm._v("\n            Role\n          ")]
                          )
                        : _vm._e(),
                      _c(
                        "base-checkbox",
                        {
                          staticClass: "ml-12 mr-6 mb-9 mt-25",
                          attrs: {
                            "starting-value": _vm.contactToEdit.is_primary
                          },
                          model: {
                            value: _vm.contactToEdit.is_primary,
                            callback: function($$v) {
                              _vm.$set(_vm.contactToEdit, "is_primary", $$v)
                            },
                            expression: "contactToEdit.is_primary"
                          }
                        },
                        [_vm._v("\n            Primary Contact\n          ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "row row--align-center" },
                    [
                      _c(
                        "base-button",
                        {
                          staticClass: "bg-blue fc-white",
                          on: { click: _vm.updateContact }
                        },
                        [_vm._v("\n            Save Contact\n          ")]
                      ),
                      _c(
                        "base-button",
                        {
                          staticClass: "bg-light fc-white ml-6",
                          on: {
                            click: function($event) {
                              _vm.editing = false
                            }
                          }
                        },
                        [_vm._v("\n            Cancel\n          ")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "fc-light fs-14 ml-12",
                          on: {
                            click: function($event) {
                              _vm.deleting = true
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fa fa-trash-can" }),
                          _vm._v("\n            Delete Contact\n          ")
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
        ]
      ),
      _c("transition", { attrs: { name: "Transition__opacity-fade" } }, [
        _vm.deleting
          ? _c("div", { staticClass: "UserProfileContacts__modal" }, [
              _c("div", { staticClass: "UserProfileContacts__modal-bg" }),
              _c(
                "div",
                { staticClass: "UserProfileContacts__modal-content column" },
                [
                  _c("label", { staticClass: "fs-20 fw-med mb-21" }, [
                    _vm._v(
                      "\n          Are you sure you would like to delete this contact?\n        "
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "row mb-21" },
                    [
                      _c(
                        "base-button",
                        {
                          staticClass:
                            "UserProfileContacts__modal-btn bg-blue fc-white mr-10",
                          on: { click: _vm.removeContact }
                        },
                        [
                          _vm._v(
                            "\n            Yes, Delete Contact\n          "
                          )
                        ]
                      ),
                      _c(
                        "base-button",
                        {
                          staticClass:
                            "UserProfileContacts__modal-btn bg-light fc-white",
                          on: {
                            click: function($event) {
                              _vm.deleting = false
                            }
                          }
                        },
                        [_vm._v("\n            No\n          ")]
                      )
                    ],
                    1
                  )
                ]
              )
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }