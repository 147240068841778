var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "column" }, [
      _c(
        "div",
        { staticClass: "row row--wrap" },
        [
          _c(
            "base-input",
            {
              staticClass: "mr-6 mb-9",
              attrs: {
                instructions: _vm.instructions.SHORTENED_NAME_INSTRUCTION,
                label: true,
                "label-for": "name",
                placeholder: "John Smith",
                valid: _vm.valid.name
              },
              model: {
                value: _vm.inputs.name,
                callback: function($$v) {
                  _vm.$set(
                    _vm.inputs,
                    "name",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "inputs.name"
              }
            },
            [_vm._v("\n        Name\n      ")]
          ),
          _c(
            "base-input",
            {
              staticClass: "mr-6 mb-9",
              attrs: {
                instructions: _vm.instructions.EMAIL_INSTRUCTION,
                label: true,
                "label-for": "email",
                placeholder: "ted@gmail.com",
                valid: _vm.valid.email
              },
              model: {
                value: _vm.inputs.email,
                callback: function($$v) {
                  _vm.$set(
                    _vm.inputs,
                    "email",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "inputs.email"
              }
            },
            [_vm._v("\n        Email\n      ")]
          ),
          _c(
            "base-input",
            {
              staticClass: "mr-6 mb-9",
              attrs: {
                instructions: _vm.valid.phone
                  ? _vm.instructions.PHONE_CONNECTED_INSTRUCTION
                  : _vm.instructions.PHONE_INSTRUCTION,
                label: true,
                "label-for": "phone",
                placeholder: "(213)361-3826",
                valid: _vm.valid.phone && _vm.connected.phone
              },
              model: {
                value: _vm.inputs.phone,
                callback: function($$v) {
                  _vm.$set(
                    _vm.inputs,
                    "phone",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "inputs.phone"
              }
            },
            [_vm._v("\n        Phone\n      ")]
          ),
          _vm.pagePOV === "debtor"
            ? _c(
                "base-input",
                {
                  staticClass: "mr-6 mb-9",
                  attrs: {
                    instructions: _vm.instructions.ROLE_INSTRUCTION,
                    label: true,
                    "label-for": "role",
                    placeholder: "Supervisor",
                    valid: _vm.valid.role
                  },
                  model: {
                    value: _vm.inputs.role,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.inputs,
                        "role",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "inputs.role"
                  }
                },
                [_vm._v("\n        Role\n      ")]
              )
            : _vm._e(),
          _c(
            "base-checkbox",
            {
              staticClass: "ml-12 mr-6 mt-25",
              attrs: { "starting-value": _vm.inputs.is_primary },
              model: {
                value: _vm.inputs.is_primary,
                callback: function($$v) {
                  _vm.$set(_vm.inputs, "is_primary", $$v)
                },
                expression: "inputs.is_primary"
              }
            },
            [_vm._v("\n        Primary Contact\n      ")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c(
            "base-button",
            {
              staticClass: "bg-blue fc-white",
              attrs: { disabled: _vm.buttonsDisabled },
              on: { click: _vm.createNewContact }
            },
            [_vm._v("\n        Create Contact\n      ")]
          ),
          _c(
            "base-button",
            {
              staticClass: "bg-light fc-white ml-6",
              attrs: { disabled: _vm.buttonsDisabled },
              on: {
                click: function($event) {
                  return _vm.$emit("cancel")
                }
              }
            },
            [_vm._v("\n        Cancel\n      ")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }