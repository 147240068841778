<template>
  <div class="NewOwnerContact row">
    <!-- DRIVER LICENSE UPLOAD -->
    <div class="UserProfileContacts__owner-docs-wrapper column">
      <label class="BaseInvoiceCreate__section-lbl fs-14 fw-med">
        DRIVERS LICENSE
      </label>
      <base-uploaded-item
        v-if="inputs.driver_license_url"
        @remove-upload="inputs.driver_license_url = null"
        :index="0"
        :filename="'driver_license'"
        :url="inputs.driver_license_url"
      />
      <base-button
        @click="uploadId"
        class="bg-green fc-white width-100"
      >
        Upload Photo
      </base-button>
    </div>
    <!-- INPUTS -->
    <div class="column ml-24">
      <div class="row row--wrap">
        <base-input
          v-model.trim="inputs.name"
          class="mr-6 mb-9"
          :instructions="instructions.SHORTENED_NAME_INSTRUCTION"
          :label="true"
          :label-for="'name'"
          :placeholder="'John Smith'"
          :valid="valid.name"
        >
          Name
        </base-input>
        <base-input
          v-model.trim="inputs.email"
          class="mr-6 mb-9"
          :instructions="instructions.EMAIL_INSTRUCTION"
          :label="true"
          :label-for="'email'"
          :placeholder="'ted@gmail.com'"
          :valid="valid.email"
        >
          Email
        </base-input>
        <base-input
          v-model.trim="inputs.phone"
          class="mr-6 mb-9"
          :instructions="
            valid.phone
              ? instructions.PHONE_CONNECTED_INSTRUCTION
              : instructions.PHONE_INSTRUCTION
          "
          :label="true"
          :label-for="'phone'"
          :placeholder="'(213)361-3826'"
          :valid="valid.phone && connected.phone"
        >
          Phone
        </base-input>
        <base-input
          v-model.trim="inputs.address"
          class="mr-6 mb-9"
          :instructions="instructions.ADDRESS_INSTRUCTION"
          :label="true"
          :label-for="'address'"
          :placeholder="'7246 Woodland Rd'"
          :valid="valid.address"
        >
          Street address
        </base-input>
        <base-input
          v-model.trim="inputs.address_2"
          class="mr-6 mb-9"
          :instructions="instructions.ADDRESS_2_INSTRUCTION"
          :label="true"
          :label-for="'address-2'"
          :placeholder="'Apt 205'"
          :valid="valid.address_2"
        >
          Address 2
        </base-input>
        <base-input
          v-model.trim="inputs.city"
          class="mr-6 mb-9"
          :instructions="instructions.CITY_INSTRUCTION"
          :label="true"
          :label-for="'city'"
          :placeholder="'Salt Lake City'"
          :valid="valid.city"
        >
          City
        </base-input>
        <div class="NewOwnerContact__select-container column mb-12">
          <label
            class="BaseInput__label uppercase"
            for="state"
          >
            State
          </label>
          <v-select
            v-model="inputs.state"
            aria-label="Select input to choose state"
            id="state"
            :class="['NewOwnerContact__select mr-6', {
              'NewOwnerContact__select--invalid': !valid.state
            }]"
            :clearable="false"
            :instructions="instructions.STATE_INSTRUCTION"
            :options="options.state"
            :placeholder="'State:'"
          />
        </div>
        <base-input
          v-model.trim="inputs.zip"
          class="mr-6 mb-9"
          :instructions="instructions.ZIP_INSTRUCTION"
          :label="true"
          :label-for="'zip'"
          :placeholder="'89009'"
          :valid="valid.zip"
        >
          Zip
        </base-input>
        <base-input
          v-model.trim="inputs.equity_stake_percentage"
          class="mr-6 mb-9"
          :instructions="instructions.EQUITY_INSTRUCTION"
          :label="true"
          :label-for="'equity_stake_percentage'"
          :placeholder="'50'"
          :valid="valid.equity_stake_percentage"
        >
          Equity stake percentage
        </base-input>
        <div class="column column--width-auto">
          <label class="fc-light fs-12 mb-7 uppercase">
            Date of Birth
          </label>
          <base-date-picker
            v-model="inputs.date_of_birth"
            class="mr-6 mb-9"
            :instruction="instructions.DATE_OF_BIRTH_INSTRUCTION"
            :placeholder="'8/31/83'"
            :valid="valid.date_of_birth"
          />
        </div>
        <base-checkbox
          v-model="inputs.is_primary"
          class="ml-12 mr-6 mt-25"
          :starting-value="inputs.is_primary"
        >
          Primary Contact
        </base-checkbox>
      </div>
      <div class="row">
        <base-button
          @click="createNewOwner"
          class="bg-blue fc-white"
          :disabled="buttonsDisabled"
        >
          Create Contact
        </base-button>
        <base-button
          @click="$emit('cancel')"
          class="bg-light fc-white ml-6"
          :disabled="buttonsDisabled"
        >
          Cancel
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
// Packages
import * as filestack from 'filestack-js'
// Helpers
import {
  ClientContact,
  ClientOwner
} from '../../../../utils/api'
import {
  formatPhoneNumber,
  formatStringCamelCase,
} from '../../../../utils/helpers'
import { statesAbbreviations } from '../../../../utils/constants'
// Components
import BaseButton from '../../../../components/base-button.vue'
import BaseCheckbox from '../../../../components/base-checkbox.vue'
import BaseDatePicker from '../../../../components/base-date-picker.vue'
import BaseInput from '../../../../components/base-input.vue'
import BaseUploadedItem from '../../../../components/base-uploaded-item.vue'
// Mixins
import {
  ValidationMixin
} from '../../../../utils/validation-mixin'

export default {
  name: 'NewOwnerContact',

  components: {
    BaseButton,
    BaseCheckbox,
    BaseDatePicker,
    BaseInput,
    BaseUploadedItem,
  },

  mixins: [ValidationMixin],

  props: {},

  data () {
    const isProd = process.env.VUE_APP_ENV === 'prod' || process.env.VUE_APP_ENV === 'prodlocal'

    return {
      buttonsDisabled: false,
      connected: {
        phone: true
      },
      filestack: {
        filestackAPIKey: 'ACrLnuwGBRv2dzbEzOiWQz',
        filestackClient: null,
        filestackPickerOptions: {
          accept: ['image/*', '.pdf'],
          allowManualRetry: true,
          // TODO: waiting on 'gmail' verification
          fromSources: ['local_file_system', 'googledrive', 'box'],
          maxFiles: 5,
        },
      },
      inputs: {
        address: isProd ? '' : '123 Test Dr.',
        address_2: isProd ? '' : 'Unit 234',
        city: isProd ? '' : 'Salt Lake City',
        date_of_birth: isProd ? '' : new Date('08/31/84'),
        email: isProd ? '' : 'john@smith.com',
        equity_stake_percentage: isProd ? '' : '51.5',
        is_primary: false,
        name: isProd ? '' : 'John Smith',
        driver_license_url: isProd
          ? ''
          : 'https://cdn.filestackcontent.com/PuDizrPLR5CL1lyUGIub',
        phone: isProd ? '' : '(123)456-1234x342',
        state: isProd ? '' : 'Utah',
        zip: isProd ? '' : '12345',
      },
      phoneData: null,
      valid: {
        // Alphabetical for location
        address: true,
        address_2: true,
        city: true,
        state: true,
        zip: true,
        // Alphabetical for the rest
        date_of_birth: true,
        equity_stake_percentage: true,
        email: true,
        name: true,
        phone: true,
        role: true,
      },
      options: {
        state: statesAbbreviations
      }
    }
  },

  computed: {
    client () {
      return this.$store.getters.client
    }
  },

  methods: {
    async createNewOwner () {
      this.buttonsDisabled = true

      if (!await this.validation({
        email: this.inputs.email,
        is_primary: this.inputs.is_primary,
        name: this.inputs.name,
        phone: this.inputs.phone,
        client_owner: {
          address: formatStringCamelCase(this.inputs.address),
          address_2: formatStringCamelCase(this.inputs.address_2),
          city: formatStringCamelCase(this.inputs.city),
          state: this.inputs.state,
          zip: this.inputs.zip,
          driver_license_url: this.inputs.driver_license_url,
          date_of_birth: this.inputs.date_of_birth,
          equity_stake_percentage: this.inputs.equity_stake_percentage,
        },
      })) {
        this.buttonsDisabled = false
        return
      }

      this.progressStart()

      try {
        const contact = (await ClientContact.create({
          client_id: this.client.id,
          email: this.inputs.email ? this.inputs.email.toLowerCase() : null,
          is_primary: this.inputs.is_primary,
          name: this.inputs.name,
          ...this.phoneData,
          send_notifications: false,
        })).data
        await ClientOwner.create({
          address: formatStringCamelCase(this.inputs.address),
          address_2: formatStringCamelCase(this.inputs.address_2),
          city: formatStringCamelCase(this.inputs.city),
          // can be called from an existing contact
          client_contact_id: contact.id,
          // can be called from ane existing contact
          client_id: contact.client_id,
          date_of_birth: this.inputs.date_of_birth,
          driver_license_url: this.inputs.driver_license_url,
          equity_stake_percentage: Number(this.inputs.equity_stake_percentage),
          ...this.phoneData,
          state: this.inputs.state,
          zip: this.inputs.zip,
        })
        this.progressFinish()
        this.$emit('created')
      }
      catch (error) {
        this.captureSentryEvent(
          'New Owner "Create"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue creating a client contact' })
      }

      this.buttonsDisabled = false
    },

    uploadId () {
      this.filestack.filestackClient = filestack.init(this.filestack.filestackAPIKey)
      this.filestack.filestackClient.picker({
        ...this.filestack.filestackPickerOptions,
        onUploadDone: (res) => {
          res.filesUploaded.forEach(file => {
            this.inputs.driver_license_url = file.url
          })
        }
      }).open()
    },

    async validation (contact) {
      let valid = true
      // name, phone, email

      // name
      if (!contact.name || !this.isValidContactName(contact.name)) {
        this.$set(this.valid, 'name', false)
        valid = false
      } else {
        this.$set(this.valid, 'name', true)
      }

      // email (not required)
      if (contact.email && !this.isValidEmail(contact.email)) {
        this.$set(this.valid, 'email', false)
        valid = false
      } else {
        this.$set(this.valid, 'email', true)
      }

      // phone (not required, format: "(area)XXX-XXXX xEXT")
      if (
        contact.phone
        && !this.isValidPhone(contact.phone)
      ) {
        this.$set(this.valid, 'phone', false)
        valid = false
      }
      else if (
        contact.phone
        && this.isValidPhone(contact.phone)
      ) {
        this.progressStart()
        this.phoneData = await this.checkPhoneNumber(contact.phone)
        // this.phoneData will be `false` if `this.phone.value` is not connected
        if (this.phoneData) this.$set(this.phoneData, 'phone', formatPhoneNumber(contact.phone))
        if (!this.phoneData.phone_valid) {
          valid = false
          this.$set(this.connected, 'phone', false)
          this.progressFail()
        } else {
          this.$set(this.connected, 'phone', true)
        }
        this.progressFinish()
      }
      else {
        this.$set(this.valid, 'phone', true)
      }

      // Either phone or email required
      if (!contact.email && !contact.phone) {
        this.alertWarning('Email or phone must be present')
        valid = false
      }

      if (this.pagePOV === 'debtor') {
        if (!contact.role || !this.isValidContactRole(contact.role)) {
          this.$set(this.valid, 'role', false)
          valid = false
        } else {
          this.$set(this.valid, 'role', true)
        }
      }

      // Address - /, -, . allowed
      if (!contact.client_owner.address || !this.isValidAddress(contact.client_owner.address)) {
        this.$set(this.valid, 'address', false)
        valid = false
      } else {
        this.$set(this.valid, 'address', true)
      }
      if (contact.client_owner.address_2) {
        if (!this.isValidAddress(contact.client_owner.address_2)) {
          this.$set(this.valid, 'address_2', false)
          valid = false
        } else {
          this.$set(this.valid, 'address_2', true)
        }
      }

      if (!contact.client_owner.city || !this.isValidCity(contact.client_owner.city)) {
        this.$set(this.valid, 'city', false)
        valid = false
      } else {
        this.$set(this.valid, 'city', true)
      }

      if (!contact.client_owner.state || !this.isValidState(contact.client_owner.state)) {
        this.$set(this.valid, 'state', false)
        valid = false
      } else {
        this.$set(this.valid, 'state', true)
      }

      if (!contact.client_owner.zip || !this.isValidZip(contact.client_owner.zip)) {
        this.$set(this.valid, 'zip', false)
        valid = false
      } else {
        this.$set(this.valid, 'zip', true)
      }

      // equity
      if (
        !contact.client_owner.equity_stake_percentage
        || !this.isValidEquity(contact.client_owner.equity_stake_percentage)
      ) {
        this.$set(this.valid, 'equity_stake_percentage', false)
        valid = false
      } else {
        this.$set(this.valid, 'equity_stake_percentage', true)
      }

      // date of birth (not required)
      if (contact.client_owner.date_of_birth && !this.isValidDateOfBirth(contact.client_owner.date_of_birth)) {
        this.$set(this.valid, 'date_of_birth', false)
        valid = false
      } else {
        this.$set(this.valid, 'date_of_birth', true)
      }

      // driver license url (not an input, but lives in the background)
      if (!contact.client_owner.driver_license_url) {
        this.alertWarning('ID must be uploaded before submitting', 'Input warning')
        valid = false
      }

      return valid
    },
  },
}
</script>

<style lang="sass">
@import '../../../../styles/user-profile.sass'

.NewOwnerContact

  &__select
    width: 100%

    &--invalid
      border: $border
      border-color: $danger
      border-radius: $border-radius

  &__select-container
    margin-right: rem(6px)
    width: rem(204px)

</style>