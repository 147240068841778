var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.userContacts
    ? _c(
        "div",
        { staticClass: "UserProfileContacts column" },
        [
          _vm.pagePOV === "client"
            ? [
                _c(
                  "div",
                  { staticClass: "column mb-30" },
                  [
                    _c(
                      "div",
                      { staticClass: "row row--justify-between mb-13" },
                      [
                        _c("label", { staticClass: "fs-18 fw-med" }, [
                          _vm._v("Owners")
                        ]),
                        _c(
                          "base-button",
                          {
                            staticClass:
                              "UserProfileContacts__new-btn bg-blue fc-white",
                            on: {
                              click: function($event) {
                                _vm.newOwner = true
                                _vm.newContact = false
                              }
                            }
                          },
                          [_vm._v("\n          Add Owner\n        ")]
                        )
                      ],
                      1
                    ),
                    _c("hr", { staticClass: "Divider mb-25" }),
                    _c(
                      "transition",
                      {
                        attrs: {
                          mode: "out-in",
                          name: "Transition__opacity-fade"
                        }
                      },
                      [
                        _vm.newOwner
                          ? _c("new-owner-contact", {
                              on: {
                                cancel: function($event) {
                                  _vm.newOwner = false
                                },
                                created: function($event) {
                                  _vm.getUserContacts()
                                  _vm.newOwner = false
                                }
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { key: "contactBaseAndEdit", staticClass: "column" },
                      _vm._l(_vm.owners, function(contact, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "UserProfileContacts__contact column"
                          },
                          [
                            _c("owner-contact", {
                              attrs: { contact: contact },
                              on: { deleted: _vm.getUserContacts }
                            })
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ],
                  1
                )
              ]
            : _vm._e(),
          [
            _c(
              "div",
              { staticClass: "column mb-30" },
              [
                _c(
                  "div",
                  { staticClass: "row row--justify-between mb-13" },
                  [
                    _c("label", { staticClass: "fs-18 fw-med" }, [
                      _vm._v("Contacts")
                    ]),
                    _c(
                      "base-button",
                      {
                        staticClass:
                          "UserProfileContacts__new-btn bg-blue fc-white",
                        on: {
                          click: function($event) {
                            _vm.newContact = true
                            _vm.newOwner = false
                          }
                        }
                      },
                      [_vm._v("\n          Add Contact\n        ")]
                    )
                  ],
                  1
                ),
                _c("hr", { staticClass: "Divider mb-25" }),
                _c(
                  "transition",
                  {
                    attrs: { mode: "out-in", name: "Transition__opacity-fade" }
                  },
                  [
                    _vm.newContact
                      ? _c("new-contact", {
                          attrs: { "page-p-o-v": _vm.pagePOV },
                          on: {
                            cancel: function($event) {
                              _vm.newContact = false
                            },
                            created: function($event) {
                              _vm.getUserContacts()
                              _vm.newContact = false
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "div",
                  { key: "contactBaseAndEdit", staticClass: "column" },
                  _vm._l(_vm.contacts, function(contact, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "UserProfileContacts__contact column"
                      },
                      [
                        _c("contact", {
                          attrs: {
                            contact: contact,
                            "page-p-o-v": _vm.pagePOV
                          },
                          on: { deleted: _vm.getUserContacts }
                        })
                      ],
                      1
                    )
                  }),
                  0
                )
              ],
              1
            )
          ]
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }