var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "NewOwnerContact row" }, [
    _c(
      "div",
      { staticClass: "UserProfileContacts__owner-docs-wrapper column" },
      [
        _c(
          "label",
          { staticClass: "BaseInvoiceCreate__section-lbl fs-14 fw-med" },
          [_vm._v("\n      DRIVERS LICENSE\n    ")]
        ),
        _vm.inputs.driver_license_url
          ? _c("base-uploaded-item", {
              attrs: {
                index: 0,
                filename: "driver_license",
                url: _vm.inputs.driver_license_url
              },
              on: {
                "remove-upload": function($event) {
                  _vm.inputs.driver_license_url = null
                }
              }
            })
          : _vm._e(),
        _c(
          "base-button",
          {
            staticClass: "bg-green fc-white width-100",
            on: { click: _vm.uploadId }
          },
          [_vm._v("\n      Upload Photo\n    ")]
        )
      ],
      1
    ),
    _c("div", { staticClass: "column ml-24" }, [
      _c(
        "div",
        { staticClass: "row row--wrap" },
        [
          _c(
            "base-input",
            {
              staticClass: "mr-6 mb-9",
              attrs: {
                instructions: _vm.instructions.SHORTENED_NAME_INSTRUCTION,
                label: true,
                "label-for": "name",
                placeholder: "John Smith",
                valid: _vm.valid.name
              },
              model: {
                value: _vm.inputs.name,
                callback: function($$v) {
                  _vm.$set(
                    _vm.inputs,
                    "name",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "inputs.name"
              }
            },
            [_vm._v("\n        Name\n      ")]
          ),
          _c(
            "base-input",
            {
              staticClass: "mr-6 mb-9",
              attrs: {
                instructions: _vm.instructions.EMAIL_INSTRUCTION,
                label: true,
                "label-for": "email",
                placeholder: "ted@gmail.com",
                valid: _vm.valid.email
              },
              model: {
                value: _vm.inputs.email,
                callback: function($$v) {
                  _vm.$set(
                    _vm.inputs,
                    "email",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "inputs.email"
              }
            },
            [_vm._v("\n        Email\n      ")]
          ),
          _c(
            "base-input",
            {
              staticClass: "mr-6 mb-9",
              attrs: {
                instructions: _vm.valid.phone
                  ? _vm.instructions.PHONE_CONNECTED_INSTRUCTION
                  : _vm.instructions.PHONE_INSTRUCTION,
                label: true,
                "label-for": "phone",
                placeholder: "(213)361-3826",
                valid: _vm.valid.phone && _vm.connected.phone
              },
              model: {
                value: _vm.inputs.phone,
                callback: function($$v) {
                  _vm.$set(
                    _vm.inputs,
                    "phone",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "inputs.phone"
              }
            },
            [_vm._v("\n        Phone\n      ")]
          ),
          _c(
            "base-input",
            {
              staticClass: "mr-6 mb-9",
              attrs: {
                instructions: _vm.instructions.ADDRESS_INSTRUCTION,
                label: true,
                "label-for": "address",
                placeholder: "7246 Woodland Rd",
                valid: _vm.valid.address
              },
              model: {
                value: _vm.inputs.address,
                callback: function($$v) {
                  _vm.$set(
                    _vm.inputs,
                    "address",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "inputs.address"
              }
            },
            [_vm._v("\n        Street address\n      ")]
          ),
          _c(
            "base-input",
            {
              staticClass: "mr-6 mb-9",
              attrs: {
                instructions: _vm.instructions.ADDRESS_2_INSTRUCTION,
                label: true,
                "label-for": "address-2",
                placeholder: "Apt 205",
                valid: _vm.valid.address_2
              },
              model: {
                value: _vm.inputs.address_2,
                callback: function($$v) {
                  _vm.$set(
                    _vm.inputs,
                    "address_2",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "inputs.address_2"
              }
            },
            [_vm._v("\n        Address 2\n      ")]
          ),
          _c(
            "base-input",
            {
              staticClass: "mr-6 mb-9",
              attrs: {
                instructions: _vm.instructions.CITY_INSTRUCTION,
                label: true,
                "label-for": "city",
                placeholder: "Salt Lake City",
                valid: _vm.valid.city
              },
              model: {
                value: _vm.inputs.city,
                callback: function($$v) {
                  _vm.$set(
                    _vm.inputs,
                    "city",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "inputs.city"
              }
            },
            [_vm._v("\n        City\n      ")]
          ),
          _c(
            "div",
            { staticClass: "NewOwnerContact__select-container column mb-12" },
            [
              _c(
                "label",
                {
                  staticClass: "BaseInput__label uppercase",
                  attrs: { for: "state" }
                },
                [_vm._v("\n          State\n        ")]
              ),
              _c("v-select", {
                class: [
                  "NewOwnerContact__select mr-6",
                  {
                    "NewOwnerContact__select--invalid": !_vm.valid.state
                  }
                ],
                attrs: {
                  "aria-label": "Select input to choose state",
                  id: "state",
                  clearable: false,
                  instructions: _vm.instructions.STATE_INSTRUCTION,
                  options: _vm.options.state,
                  placeholder: "State:"
                },
                model: {
                  value: _vm.inputs.state,
                  callback: function($$v) {
                    _vm.$set(_vm.inputs, "state", $$v)
                  },
                  expression: "inputs.state"
                }
              })
            ],
            1
          ),
          _c(
            "base-input",
            {
              staticClass: "mr-6 mb-9",
              attrs: {
                instructions: _vm.instructions.ZIP_INSTRUCTION,
                label: true,
                "label-for": "zip",
                placeholder: "89009",
                valid: _vm.valid.zip
              },
              model: {
                value: _vm.inputs.zip,
                callback: function($$v) {
                  _vm.$set(
                    _vm.inputs,
                    "zip",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "inputs.zip"
              }
            },
            [_vm._v("\n        Zip\n      ")]
          ),
          _c(
            "base-input",
            {
              staticClass: "mr-6 mb-9",
              attrs: {
                instructions: _vm.instructions.EQUITY_INSTRUCTION,
                label: true,
                "label-for": "equity_stake_percentage",
                placeholder: "50",
                valid: _vm.valid.equity_stake_percentage
              },
              model: {
                value: _vm.inputs.equity_stake_percentage,
                callback: function($$v) {
                  _vm.$set(
                    _vm.inputs,
                    "equity_stake_percentage",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "inputs.equity_stake_percentage"
              }
            },
            [_vm._v("\n        Equity stake percentage\n      ")]
          ),
          _c(
            "div",
            { staticClass: "column column--width-auto" },
            [
              _c("label", { staticClass: "fc-light fs-12 mb-7 uppercase" }, [
                _vm._v("\n          Date of Birth\n        ")
              ]),
              _c("base-date-picker", {
                staticClass: "mr-6 mb-9",
                attrs: {
                  instruction: _vm.instructions.DATE_OF_BIRTH_INSTRUCTION,
                  placeholder: "8/31/83",
                  valid: _vm.valid.date_of_birth
                },
                model: {
                  value: _vm.inputs.date_of_birth,
                  callback: function($$v) {
                    _vm.$set(_vm.inputs, "date_of_birth", $$v)
                  },
                  expression: "inputs.date_of_birth"
                }
              })
            ],
            1
          ),
          _c(
            "base-checkbox",
            {
              staticClass: "ml-12 mr-6 mt-25",
              attrs: { "starting-value": _vm.inputs.is_primary },
              model: {
                value: _vm.inputs.is_primary,
                callback: function($$v) {
                  _vm.$set(_vm.inputs, "is_primary", $$v)
                },
                expression: "inputs.is_primary"
              }
            },
            [_vm._v("\n        Primary Contact\n      ")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c(
            "base-button",
            {
              staticClass: "bg-blue fc-white",
              attrs: { disabled: _vm.buttonsDisabled },
              on: { click: _vm.createNewOwner }
            },
            [_vm._v("\n        Create Contact\n      ")]
          ),
          _c(
            "base-button",
            {
              staticClass: "bg-light fc-white ml-6",
              attrs: { disabled: _vm.buttonsDisabled },
              on: {
                click: function($event) {
                  return _vm.$emit("cancel")
                }
              }
            },
            [_vm._v("\n        Cancel\n      ")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }