<template>
  <div class="width-100">
    <transition
      mode="out-in"
      name="Transition__opacity-fade"
    >
      <!-- OWNERS - BASE -->
      <div
        v-if="!editing"
        class="column"
        key="contact"
      >
        <div class="row">
          <div class="row row--align-center mb-8">
            <label class="fs-16 fw-med capitalize">{{ contactDefault.name }}</label>
            <label
              v-if="contactDefault.is_primary"
              class="UserProfileContacts__flag-lbl fs-12 uppercase bg-green ml-8"
            >
              PRIMARY CONTACT
            </label>
          </div>
          <div class="row row--justify-end">
            <button
              @click="editContact"
              class="fc-light fs-14 mr-25"
            >
              <i class="fa fa-edit fc-light" />
              Edit
            </button>
            <button
              v-if="pagePOV === 'client'"
              @click="toggleContactNotifications"
              :class="['fs-14', {
                'fc-blue': contactDefault.send_notifications,
                'fc-light': !contactDefault.send_notifications,
              }]"
            >
              <i
                :class="['fa fa-bell', {
                  'fc-blue': contactDefault.send_notifications,
                  'fc-light': !contactDefault.send_notifications,
                }]"
              />
              Notifications {{ contactDefault.send_notifications ? 'On' : 'Off' }}
            </button>
          </div>
        </div>
        <div class="column">
          <label class="fc-light fs-14">
            {{ contactDefault.email }}
            {{ contactDefault.email && contactDefault.phone ? '|' : null }}
            {{ contactDefault.phone }}
          </label>
          <label class="fc-light fs-14"> {{ contactDefault.role }}</label>
        </div>
      </div>
      <!-- OWNERS - EDIT -->
      <div
        v-else
        class="row"
        key="contactEdit"
      >
        <div class="column">
          <!-- EDIT INPUTS -->
          <div class="row row--wrap">
            <base-input
              v-model.trim="contactToEdit.name"
              class="mr-6 mb-9"
              :instructions="instructions.SHORTENED_NAME_INSTRUCTION"
              :label="true"
              :label-for="'name'"
              :placeholder="'John Smith'"
              :valid="valid.name"
            >
              Name
            </base-input>
            <base-input
              v-model.trim="contactToEdit.email"
              class="mr-6 mb-9"
              :instructions="instructions.EMAIL_INSTRUCTION"
              :label="true"
              :label-for="'email'"
              :placeholder="'ted@gmail.com'"
              :valid="valid.email"
            >
              Email
            </base-input>
            <base-input
              v-model.trim="contactToEdit.phone"
              class="mr-6 mb-9"
              :instructions="
                valid.phone
                  ? instructions.PHONE_CONNECTED_INSTRUCTION
                  : instructions.PHONE_INSTRUCTION
              "
              :label="true"
              :label-for="'phone'"
              :placeholder="'(213)361-3826'"
              :valid="valid.phone && connected.phone"
            >
              Phone
            </base-input>
            <base-input
              v-if="pagePOV === 'debtor'"
              v-model.trim="contactToEdit.role"
              class="mr-6 mb-9"
              :instructions="instructions.ROLE_INSTRUCTION"
              :label="true"
              :label-for="'role'"
              :placeholder="'Supervisor'"
              :valid="valid.role"
            >
              Role
            </base-input>
            <base-checkbox
              v-model="contactToEdit.is_primary"
              class="ml-12 mr-6 mb-9 mt-25"
              :starting-value="contactToEdit.is_primary"
            >
              Primary Contact
            </base-checkbox>
          </div>
          <!-- EDIT BUTTONS -->
          <div class="row row--align-center">
            <base-button
              @click="updateContact"
              class="bg-blue fc-white"
            >
              Save Contact
            </base-button>
            <base-button
              @click="editing = false"
              class="bg-light fc-white ml-6"
            >
              Cancel
            </base-button>
            <button
              @click="deleting = true"
              class="fc-light fs-14 ml-12"
            >
              <i class="fa fa-trash-can" />
              Delete Contact
            </button>
          </div>
        </div>
      </div>
    </transition>

    <!-- DELETE MODAL -->
    <transition name="Transition__opacity-fade">
      <div
        v-if="deleting"
        class="UserProfileContacts__modal"
      >
        <div class="UserProfileContacts__modal-bg" />
        <div class="UserProfileContacts__modal-content column">
          <label class="fs-20 fw-med mb-21">
            Are you sure you would like to delete this contact?
          </label>
          <div class="row mb-21">
            <base-button
              @click="removeContact"
              class="UserProfileContacts__modal-btn bg-blue fc-white mr-10"
            >
              Yes, Delete Contact
            </base-button>
            <base-button
              @click="deleting = false"
              class="UserProfileContacts__modal-btn bg-light fc-white"
            >
              No
            </base-button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// Helpers
import {
  ClientContact,
  DebtorContact
} from '../../../../utils/api'
import { formatPhoneNumber } from '../../../../utils/helpers'
// Components
import BaseButton from '../../../../components/base-button.vue'
import BaseCheckbox from '../../../../components/base-checkbox.vue'
import BaseInput from '../../../../components/base-input.vue'
// Mixins
import {
  ValidationMixin
} from '../../../../utils/validation-mixin'

export default {
  name: 'Contact',

  components: {
    BaseButton,
    BaseCheckbox,
    BaseInput,
  },

  mixins: [ValidationMixin],

  props: {
    contact: {
      type: Object,
      required: true,
    },
    pagePOV: {
      type: String,
      required: true,
    },
  },

  watch: {
    contact () {
      this.contactDefault = this.contact
    },
  },

  data () {
    return {
      connected: {
        phone: true
      },
      // This allows us to update the contact in this component w/o parent input
      contactDefault: { ...this.contact },
      contactToEdit: null,
      deleting: false,
      editing: false,
      phoneData: null,
      valid: {
        email: true,
        name: true,
        phone: true,
        role: true,
      },
    }
  },

  methods: {
    editContact () {
      this.editing = true
      // Have to deep clone
      this.contactToEdit = JSON.parse(JSON.stringify(this.contactDefault))
      this.resetValidState()
    },

    async getClientContact () {
      try {
        this.contactDefault = (await ClientContact.get(this.contact.id)).data
        this.contactToEdit = this.contactDefault // reset editing inputs
      }
      catch (error) {
        this.captureSentryEvent(
          'Contact "Get Client Contact"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting the updated contact' })
      }
    },

    async getDebtorContact () {
      try {
        this.contactDefault = (await DebtorContact.get(this.contact.id)).data
        this.contactToEdit = this.contactDefault // reset editing inputs
      }
      catch (error) {
        this.captureSentryEvent(
          'Contact "Get Debtor Contact"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting the updated contact' })
      }
    },

    async removeContact () {
      this.progressStart()

      try {
        if (this.pagePOV === 'client') {
          await ClientContact.delete(this.contactDefault.id)
        } else if (this.pagePOV === 'debtor') {
          await DebtorContact.delete(this.contactDefault.id)
        }
        this.progressFinish()
        this.$emit('deleted')
        this.deleting = false
        this.editing = false
      }
      catch (error) {
        this.captureSentryEvent(
          'Contact "Remove"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue removing the client\'s contact' })
      }
    },

    resetValidState () {
      this.valid = Object.assign(this.valid, {
        email: true,
        name: true,
        phone: true,
        role: true,
      })
    },

    async toggleContactNotifications () {
      if (this.pagePOV === 'debtor') return
      this.contactToEdit = JSON.parse(JSON.stringify(this.contactDefault))
      this.$set(this.contactToEdit, 'send_notifications', !this.contactToEdit.send_notifications)
      await this.updateContact()
    },

    async updateContact () {
      if (!await this.validation(this.contactToEdit)) return

      this.progressStart()

      try {
        const contact = Object.assign({}, this.contactToEdit, {
          email: this.contactToEdit.email ? this.contactToEdit.email.toLowerCase() : null,
        })
        if (this.pagePOV === 'client') {
          await ClientContact.update({
            ...contact,
            ...this.phoneData,
          })
          await this.getClientContact()
        }

        if (this.pagePOV === 'debtor') {
          await DebtorContact.update({
            ...contact,
            ...this.phoneData,
          })
          await this.getDebtorContact()
        }

        this.editing = false
        this.progressFinish()
      }
      catch (error) {
        this.captureSentryEvent(
          'Contact "Update"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue updating the contact' })
      }
    },

    async validation (contact) {
      let valid = true
      // name, phone, email

      // name
      if (!contact.name || !this.isValidContactName(contact.name)) {
        this.$set(this.valid, 'name', false)
        valid = false
      } else {
        this.$set(this.valid, 'name', true)
      }

      // email (not required)
      if (contact.email && !this.isValidEmail(contact.email)) {
        this.$set(this.valid, 'email', false)
        valid = false
      } else {
        this.$set(this.valid, 'email', true)
      }

      // phone(not required)
      if (
        contact.phone
        && !this.isValidPhone(contact.phone)
      ) {
        this.$set(this.valid, 'phone', false)
        valid = false
      }
      else if (
        contact.phone
        && this.isValidPhone(contact.phone)
      ) {
        this.progressStart()
        this.phoneData = await this.checkPhoneNumber(contact.phone)
        // this.phoneData will be `false` if `this.phone.value` is not connected
        if (this.phoneData) this.$set(this.phoneData, 'phone', formatPhoneNumber(contact.phone))
        if (!this.phoneData.phone_valid) {
          valid = false
          this.$set(this.connected, 'phone', false)
          this.progressFail()
        } else {
          this.$set(this.connected, 'phone', true)
        }
        this.progressFinish()
      }
      else {
        this.$set(this.valid, 'phone', true)
        // Manually set all phone data (from Twilio) to null
        this.phoneData = null
      }

      // Either phone or email required
      if (!contact.email && !contact.phone) {
        this.alertWarning('Email or phone must be present')
        valid = false
      }

      if (this.pagePOV === 'debtor') {
        if (!contact.role || !this.isValidContactRole(contact.role)) {
          this.$set(this.valid, 'role', false)
          valid = false
        } else {
          this.$set(this.valid, 'role', true)
        }
      }

      return valid
    },
  }
}
</script>

<style lang="sass">
@import '../../../../styles/user-profile.sass'
</style>