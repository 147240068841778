<template>
  <div class="OwnerContact width-100">
    <transition
      mode="out-in"
      name="Transition__opacity-fade"
    >
      <!-- OWNERS - BASE -->
      <div
        v-if="!editing"
        class="column"
        key="contact"
      >
        <div class="row">
          <div class="row row--align-center mb-4">
            <label class="fs-16 fw-med capitalize">{{ contactDefault.name }}</label>
            <label
              v-if="contactDefault.client_owner.equity_stake_percentage > 0"
              class="fc-light fs-12 fw-med ml-8"
            >
              {{ Number(contactDefault.client_owner.equity_stake_percentage).toFixed(2) }}%
            </label>
            <label
              v-if="contactDefault.is_primary"
              class="UserProfileContacts__flag-lbl fs-12 uppercase bg-green ml-8"
            >
              PRIMARY CONTACT
            </label>
          </div>
          <div class="row row--justify-end">
            <button
              @click="editContact"
              class="fc-light fs-14 mr-25"
            >
              <i class="fa fa-edit fc-light" />
              Edit
            </button>
            <button
              @click="toggleContactNotifications"
              :class="['fs-14', {
                'fc-blue': contactDefault.send_notifications,
                'fc-light': !contactDefault.send_notifications,
              }]"
            >
              <i
                :class="['fa fa-bell', {
                  'fc-blue': contactDefault.send_notifications,
                  'fc-light': !contactDefault.send_notifications,
                }]"
              />
              Notifications {{ contactDefault.send_notifications ? 'On' : 'Off' }}
            </button>
          </div>
        </div>
        <div class="row row--justify-between row--align-end">
          <div class="column">
            <a
              :href="contactDefault.client_owner.driver_license_url"
              class="mb-4"
              target="_blank"
              title="View the contact's drivers license"
            >
              <i class="fa fa-license fc-light fa--none" />
              <span class="fc-light fs-14 ml-7">Drivers License</span>
            </a>
            <label class="fc-light fs-14 mb-2">
              {{ contactDefault.client_owner.address }}{{ contactDefault.client_owner.address_2
                ? ` ${contactDefault.client_owner.address_2},`
                : ','
              }}
              {{ `${contactDefault.client_owner.city},` }} {{ contactDefault.client_owner.state }}
              {{ contactDefault.client_owner.zip }}
            </label>
            <label class="fc-light fs-14 mb-2">
              {{ contactDefault.email }}
              {{ contactDefault.email && contactDefault.phone ? '|' : null }}
              {{ contactDefault.phone }}
            </label>
          </div>
        </div>
        <div class="row">
          <label class="fc-light fs-14 mb-2">
            {{ contactDefault.client_owner.date_of_birth | datetime('MM/DD/YYYY') }}
          </label>
        </div>
      </div>
      <!-- OWNERS - EDIT -->
      <div
        v-else
        class="row"
        key="contactEdit"
      >
        <!-- EDIT DOCUMENTS -->
        <div class="UserProfileContacts__owner-docs-wrapper column">
          <label class="BaseInvoiceCreate__section-lbl fs-14 fw-med">
            DRIVERS LICENSE
          </label>
          <base-uploaded-item
            v-if="contactToEdit.client_owner.driver_license_url"
            @remove-upload="$set(contactToEdit.client_owner, 'driver_license_url', null)"
            :index="0"
            :filename="'driver_license'"
            :url="contactToEdit.client_owner.driver_license_url"
          />
          <base-button
            @click="uploadId"
            class="bg-green fc-white width-100"
          >
            Upload Photo
          </base-button>
        </div>
        <div class="column ml-24">
          <!-- EDIT INPUTS -->
          <div class="row row--wrap">
            <base-input
              v-model.trim="contactToEdit.name"
              class="mr-6 mb-9"
              :instructions="instructions.SHORTENED_NAME_INSTRUCTION"
              :label="true"
              :label-for="'name'"
              :placeholder="'John Smith'"
              :valid="valid.name"
            >
              Name
            </base-input>
            <base-input
              v-model.trim="contactToEdit.email"
              class="mr-6 mb-9"
              :instructions="instructions.EMAIL_INSTRUCTION"
              :label="true"
              :label-for="'email'"
              :placeholder="'ted@gmail.com'"
              :valid="valid.email"
            >
              Email
            </base-input>
            <base-input
              v-model.trim="contactToEdit.phone"
              class="mr-6 mb-9"
              :instructions="
                valid.phone
                  ? instructions.PHONE_CONNECTED_INSTRUCTION
                  : instructions.PHONE_INSTRUCTION
              "
              :label="true"
              :label-for="'phone'"
              :placeholder="'(213)361-3826'"
              :valid="valid.phone && connected.phone"
            >
              Phone
            </base-input>
            <base-input
              v-model.trim="contactToEdit.client_owner.address"
              class="mr-6 mb-9"
              :instructions="instructions.ADDRESS_INSTRUCTION"
              :label="true"
              :label-for="'address'"
              :placeholder="'7246 Woodland Rd'"
              :valid="valid.address"
            >
              Address
            </base-input>
            <base-input
              v-model.trim="contactToEdit.client_owner.address_2"
              class="mr-6 mb-9"
              :instructions="instructions.ADDRESS_2_INSTRUCTION"
              :label="true"
              :label-for="'address_2'"
              :placeholder="'Apt 205'"
              :valid="valid.address_2"
            >
              Address 2
            </base-input>
            <base-input
              v-model.trim="contactToEdit.client_owner.city"
              class="mr-6 mb-9"
              :instructions="instructions.CITY_INSTRUCTION"
              :label="true"
              :label-for="'city'"
              :placeholder="'Salt Lake City'"
              :valid="valid.city"
            >
              City
            </base-input>
            <div class="OwnerContact__select-container column mb-12 mr-6">
              <label
                class="BaseInput__label uppercase"
                for="state"
              >
                State
              </label>
              <v-select
                v-model="contactToEdit.client_owner.state"
                aria-label="Select input to choose state"
                id="state"
                :class="['OwnerContact__select', {
                  'OwnerContact__select--invalid': !valid.state
                }]"
                :clearable="false"
                :instructions="instructions.STATE_INSTRUCTION"
                :options="options.state"
                :placeholder="'State:'"
              />
            </div>
            <base-input
              v-model.trim="contactToEdit.client_owner.zip"
              class="mr-6 mb-9"
              :instructions="instructions.ZIP_INSTRUCTION"
              :label="true"
              :label-for="'zip'"
              :placeholder="'89009'"
              :valid="valid.zip"
            >
              Zip
            </base-input>
            <base-input
              v-model.trim="contactToEdit.client_owner.equity_stake_percentage"
              class="mr-6 mb-9"
              :instructions="instructions.EQUITY_INSTRUCTION"
              :label="true"
              :label-for="instructions.EQUITY_INSTRUCTION"
              :placeholder="'50'"
              :valid="valid.equity_stake_percentage"
            >
              Equity stake percentage
            </base-input>
            <div class="column column--width-auto">
              <label class="fc-light fs-12 mb-7 uppercase">
                Date of Birth
              </label>
              <base-date-picker
                v-model="contactToEdit.client_owner.date_of_birth"
                class="mr-6 mb-9"
                :instruction="instructions.DATE_OF_BIRTH_INSTRUCTION"
                :placeholder="'8/31/83'"
                :valid="valid.date_of_birth"
              />
            </div>
            <base-checkbox
              v-model="contactToEdit.is_primary"
              class="ml-12 mr-6 mt-25"
              :starting-value="contactToEdit.is_primary"
            >
              Primary Contact
            </base-checkbox>
          </div>
          <!-- EDIT BUTTONS -->
          <div class="row row--align-center">
            <base-button
              @click="updateOwner"
              class="bg-blue fc-white"
            >
              Save Contact
            </base-button>
            <base-button
              @click="editing = false"
              class="bg-light fc-white ml-6"
            >
              Cancel
            </base-button>
            <button
              @click="deleting = true"
              class="fc-light fs-14 ml-12"
            >
              <i class="fa fa-trash-can" />
              Delete Contact
            </button>
          </div>
        </div>
      </div>
    </transition>


    <!-- DELETE MODAL -->
    <transition name="Transition__opacity-fade">
      <div
        v-if="deleting"
        class="UserProfileContacts__modal"
      >
        <div class="UserProfileContacts__modal-bg" />
        <div class="UserProfileContacts__modal-content column">
          <label class="fs-20 fw-med mb-21">
            Are you sure you would like to delete this contact?
          </label>
          <div class="row mb-21">
            <base-button
              @click="removeOwner"
              class="UserProfileContacts__modal-btn bg-blue fc-white mr-10"
            >
              Yes, Delete Contact
            </base-button>
            <base-button
              @click="deleting = false"
              class="UserProfileContacts__modal-btn bg-light fc-white"
            >
              No
            </base-button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// Packages
import * as filestack from 'filestack-js'
// Helpers
import {
  ClientContact,
  ClientOwner
} from '../../../../utils/api'
import {
  formatPhoneNumber,
  formatStringCamelCase
} from '../../../../utils/helpers'
import { statesAbbreviations } from '../../../../utils/constants'
// Components
import BaseButton from '../../../../components/base-button.vue'
import BaseCheckbox from '../../../../components/base-checkbox.vue'
import BaseDatePicker from '../../../../components/base-date-picker.vue'
import BaseInput from '../../../../components/base-input.vue'
import BaseUploadedItem from '../../../../components/base-uploaded-item.vue'
// Mixins
import {
  ValidationMixin
} from '../../../../utils/validation-mixin'

export default {
  name: 'OwnerContact',

  components: {
    BaseButton,
    BaseCheckbox,
    BaseDatePicker,
    BaseInput,
    BaseUploadedItem,
  },

  mixins: [ValidationMixin],

  props: {
    contact: {
      type: Object,
      required: true,
    },
  },

  watch: {
    contact () {
      this.contactDefault = this.contact
    },
  },

  data () {
    return {
      connected: {
        phone: true
      },
      // This allows us to update the contact in this component w/o parent input
      contactDefault: { ...this.contact },
      contactToEdit: null,
      deleting: false,
      editing: false,
      filestack: {
        filestackAPIKey: 'ACrLnuwGBRv2dzbEzOiWQz',
        filestackClient: null,
        filestackPickerOptions: {
          accept: ['image/*', '.pdf'],
          allowManualRetry: true,
          // TODO: waiting on 'gmail' verification
          fromSources: ['local_file_system', 'googledrive', 'box'],
          maxFiles: 5,
        },
      },
      phoneDate: null,
      valid: {
        // Alphabetical for address
        address: true,
        address_2: true,
        city: true,
        state: true,
        zip: true,
        // Alphabetical for the rest
        date_of_birth: true,
        equity_stake_percentage: true,
        email: true,
        name: true,
        phone: true,
        role: true,
      },
      options: {
        state: statesAbbreviations
      }
    }
  },

  methods: {
    editContact () {
      this.editing = true
      // Have to deep clone
      this.contactToEdit = JSON.parse(JSON.stringify(this.contactDefault))
      this.resetValidState()
    },

    async getClientContact () {
      try {
        this.contactDefault = (await ClientContact.get(this.contact.id)).data
        this.contactToEdit = this.contactDefault // reset editing inputs
        this.editing = false
        this.progressFinish()
      }
      catch (error) {
        this.captureSentryEvent(
          'New Owner "Create"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting the updated contact' })
      }
    },

    async removeOwner () {
      this.progressStart()

      try {
        await ClientOwner.delete(this.contactDefault.client_owner.id)
        await ClientContact.delete(this.contactDefault.id)
        this.progressFinish()
        this.$emit('deleted')
        this.deleting = false
      }
      catch (error) {
        this.captureSentryEvent(
          'Owner Contact "Remove"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue removing the client\'s contact' })
      }
    },

    resetValidState () {
      this.valid = Object.assign(this.valid, {
        address: true,
        address_2: true,
        city: true,
        state: true,
        zip: true,
        equity_stake_percentage: true,
        email: true,
        name: true,
        phone: true,
        role: true,
      })
    },

    async toggleContactNotifications () {
      // Deep clone
      this.contactToEdit = JSON.parse(JSON.stringify(this.contactDefault))
      this.$set(this.contactToEdit, 'send_notifications', !this.contactToEdit.send_notifications)
      await this.updateOwner()
    },

    async updateOwner () {
      if (!await this.validation(this.contactToEdit)) return

      this.progressStart()

      const editedContact = Object.assign({}, this.contactToEdit, {
        email: this.contactToEdit.email ? this.contactToEdit.email.toLowerCase() : null,
      })
      // Format address, address_2, city, email, and state
      const editedOwner = Object.assign({}, this.contactToEdit.client_owner, {
        address: formatStringCamelCase(this.contactToEdit.client_owner.address),
        address_2: formatStringCamelCase(this.contactToEdit.client_owner.address_2),
        city: formatStringCamelCase(this.contactToEdit.client_owner.city),
        date_of_birth: this.contactToEdit.client_owner.date_of_birth,
        email: this.contactToEdit.email ? this.contactToEdit.email.toLowerCase() : null,
        state: this.contactToEdit.client_owner.state,
      })

      try {
        await ClientContact.update({
          ...editedContact,
          ...this.phoneData,
        })
        await ClientOwner.update({
          ...editedOwner,
          ...this.phoneData,
        })
        this.getClientContact()
      }
      catch (error) {
        this.captureSentryEvent(
          'Owner Contact "Update"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue updating the contact' })
      }
    },

    uploadId () {
      this.filestack.filestackClient = filestack.init(this.filestack.filestackAPIKey)
      this.filestack.filestackClient.picker({
        ...this.filestack.filestackPickerOptions,
        onUploadDone: (res) => {
          res.filesUploaded.forEach(file => {
            this.contactToEdit.client_owner.driver_license_url = file.url
          })
        }
      }).open()
    },

    async validation (contact) {
      let valid = true
      // name, phone, email

      // name
      if (!contact.name || !this.isValidContactName(contact.name)) {
        this.$set(this.valid, 'name', false)
        valid = false
      } else {
        this.$set(this.valid, 'name', true)
      }

      // email (not required)
      if (contact.email && !this.isValidEmail(contact.email)) {
        this.$set(this.valid, 'email', false)
        valid = false
      } else {
        this.$set(this.valid, 'email', true)
      }

      // phone (not required, format: "(area)XXX-XXXX xEXT")
      if (
        contact.phone
        && !this.isValidPhone(contact.phone)
      ) {
        this.$set(this.valid, 'phone', false)
        valid = false
      }
      else if (
        contact.phone
        && this.isValidPhone(contact.phone)
      ) {
        this.progressStart()
        this.phoneData = await this.checkPhoneNumber(contact.phone)
        // this.phoneData will be `false` if `this.phone.value` is not connected
        if (this.phoneData) this.$set(this.phoneData, 'phone', formatPhoneNumber(contact.phone))
        if (!this.phoneData.phone_valid) {
          valid = false
          this.$set(this.connected, 'phone', false)
          this.progressFail()
        } else {
          this.$set(this.connected, 'phone', true)
        }
        this.progressFinish()
      }
      else {
        this.$set(this.valid, 'phone', true)
      }

      // Either phone or email required
      if (!contact.email && !contact.phone) {
        this.alertWarning('Email or phone must be present')
        valid = false
      }

      if (this.pagePOV === 'debtor') {
        if (!contact.role || !this.isValidContactRole(contact.role)) {
          this.$set(this.valid, 'role', false)
          valid = false
        } else {
          this.$set(this.valid, 'role', true)
        }
      }

      // Address - /, -, . allowed
      if (!contact.client_owner.address || !this.isValidAddress(contact.client_owner.address)) {
        this.$set(this.valid, 'address', false)
        valid = false
      } else {
        this.$set(this.valid, 'address', true)
      }
      if (contact.client_owner.address_2) {
        if (!this.isValidAddress(contact.client_owner.address_2)) {
          this.$set(this.valid, 'address_2', false)
          valid = false
        } else {
          this.$set(this.valid, 'address_2', true)
        }
      }

      if (!contact.client_owner.city || !this.isValidCity(contact.client_owner.city)) {
        this.$set(this.valid, 'city', false)
        valid = false
      } else {
        this.$set(this.valid, 'city', true)
      }

      if (!contact.client_owner.state || !this.isValidState(contact.client_owner.state)) {
        this.$set(this.valid, 'state', false)
        valid = false
      } else {
        this.$set(this.valid, 'state', true)
      }

      if (!contact.client_owner.zip || !this.isValidZip(contact.client_owner.zip)) {
        this.$set(this.valid, 'zip', false)
        valid = false
      } else {
        this.$set(this.valid, 'zip', true)
      }

      // equity
      if (
        !contact.client_owner.equity_stake_percentage
        || !this.isValidEquity(contact.client_owner.equity_stake_percentage)
      ) {
        this.$set(this.valid, 'equity_stake_percentage', false)
        valid = false
      } else {
        this.$set(this.valid, 'equity_stake_percentage', true)
      }

      // date of birth (not required)
      if (contact.client_owner.date_of_birth && !this.isValidDateOfBirth(contact.client_owner.date_of_birth)) {
        this.$set(this.valid, 'date_of_birth', false)
        valid = false
      } else {
        this.$set(this.valid, 'date_of_birth', true)
      }

      // driver license url (not an input, but lives in the background)
      if (!contact.client_owner.driver_license_url) {
        this.alertWarning('ID must be uploaded before submitting', 'Input warning')
        valid = false
      }

      // When toggling notifications, all the information for an owner, must be valid, but if not
      // we need to open editing and display the error messaging for what isn't valid
      if (!valid) {
        this.editing = true
      }

      return valid
    },
  }
}
</script>

<style lang="sass">
@import '../../../../styles/user-profile.sass'

.OwnerContact

  &__select
    width: 100%

    &--invalid
      border: $border
      border-color: $danger
      border-radius: $border-radius

  &__select-container
    width: rem(204px)

</style>