<template>
  <div class="row">
    <!-- INPUTS -->
    <div class="column">
      <div class="row row--wrap">
        <base-input
          v-model.trim="inputs.name"
          class="mr-6 mb-9"
          :instructions="instructions.SHORTENED_NAME_INSTRUCTION"
          :label="true"
          :label-for="'name'"
          :placeholder="'John Smith'"
          :valid="valid.name"
        >
          Name
        </base-input>
        <base-input
          v-model.trim="inputs.email"
          class="mr-6 mb-9"
          :instructions="instructions.EMAIL_INSTRUCTION"
          :label="true"
          :label-for="'email'"
          :placeholder="'ted@gmail.com'"
          :valid="valid.email"
        >
          Email
        </base-input>
        <base-input
          v-model.trim="inputs.phone"
          class="mr-6 mb-9"
          :instructions="
            valid.phone
              ? instructions.PHONE_CONNECTED_INSTRUCTION
              : instructions.PHONE_INSTRUCTION
          "
          :label="true"
          :label-for="'phone'"
          :placeholder="'(213)361-3826'"
          :valid="valid.phone && connected.phone"
        >
          Phone
        </base-input>
        <base-input
          v-if="pagePOV === 'debtor'"
          v-model.trim="inputs.role"
          class="mr-6 mb-9"
          :instructions="instructions.ROLE_INSTRUCTION"
          :label="true"
          :label-for="'role'"
          :placeholder="'Supervisor'"
          :valid="valid.role"
        >
          Role
        </base-input>
        <base-checkbox
          v-model="inputs.is_primary"
          class="ml-12 mr-6 mt-25"
          :starting-value="inputs.is_primary"
        >
          Primary Contact
        </base-checkbox>
      </div>
      <div class="row">
        <base-button
          @click="createNewContact"
          class="bg-blue fc-white"
          :disabled="buttonsDisabled"
        >
          Create Contact
        </base-button>
        <base-button
          @click="$emit('cancel')"
          class="bg-light fc-white ml-6"
          :disabled="buttonsDisabled"
        >
          Cancel
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
// Helpers
import {
  ClientContact,
  DebtorContact
} from '../../../../utils/api'
import {
  formatPhoneNumber,
} from '../../../../utils/helpers'
// Components
import BaseButton from '../../../../components/base-button.vue'
import BaseCheckbox from '../../../../components/base-checkbox.vue'
import BaseInput from '../../../../components/base-input.vue'
// Mixins
import {
  ValidationMixin
} from '../../../../utils/validation-mixin'

export default {
  name: 'NewOwnerContact',

  components: {
    BaseButton,
    BaseCheckbox,
    BaseInput,
  },

  mixins: [ValidationMixin],

  props: {
    pagePOV: {
      type: String,
      required: true,
    },
  },

  data () {
    const isProd = process.env.VUE_APP_ENV === 'prod' || process.env.VUE_APP_ENV === 'prodlocal'

    return {
      buttonsDisabled: false,
      connected: {
        phone: true
      },
      inputs: {
        email: isProd ? '' : 'john@smith.com',
        is_primary: false,
        name: isProd ? '' : 'John Smith',
        phone: isProd ? '' : '(123)456-1234x342',
        role: '',
      },
      phoneData: null,
      valid: {
        email: true,
        name: true,
        phone: true,
        role: true,
      }
    }
  },

  computed: {
    user () {
      if (this.pagePOV === 'client') {
        return this.$store.getters.client
      }
      return this.$store.getters.debtor
    }
  },

  methods: {
    async createNewContact () {
      this.buttonsDisabled = true

      if (!await this.validation({
        email: this.inputs.email,
        is_primary: this.inputs.is_primary,
        name: this.inputs.name,
        phone: this.inputs.phone,
        role: this.inputs.role,
      })) {
        this.buttonsDisabled = false
        return
      }

      this.progressStart()

      try {
        if (this.pagePOV === 'client') {
          await ClientContact.create({
            client_id: this.user.id,
            email: this.inputs.email ? this.inputs.email.toLowerCase() : null,
            is_primary: this.inputs.is_primary,
            name: this.inputs.name,
            ...this.phoneData,
            send_notifications: false,
          })
        } else if (this.pagePOV === 'debtor') {
          await DebtorContact.create({
            debtor_id: this.user.id,
            email: this.inputs.email ? this.inputs.email.toLowerCase() : null,
            is_primary: this.inputs.is_primary,
            name: this.inputs.name,
            ...this.phoneData,
            role: this.inputs.role,
            send_notifications: false,
          })
        }
        this.progressFinish()
        this.$emit('created')
      }
      catch (error) {
        this.captureSentryEvent(
          'New Contact "Create"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue creating a client contact' })
      }

      this.buttonsDisabled = false
    },

    async validation (contact) {
      let valid = true
      // name, phone, email

      // name
      if (!contact.name || !this.isValidContactName(contact.name)) {
        this.$set(this.valid, 'name', false)
        valid = false
      } else {
        this.$set(this.valid, 'name', true)
      }

      // email (not required)
      if (contact.email && !this.isValidEmail(contact.email)) {
        this.$set(this.valid, 'email', false)
        valid = false
      } else {
        this.$set(this.valid, 'email', true)
      }

      // phone (not required)
      if (
        contact.phone
        && !this.isValidPhone(contact.phone)
      ) {
        this.$set(this.valid, 'phone', false)
        valid = false
      }
      else if (
        contact.phone
        && this.isValidPhone(contact.phone)
      ) {
        this.progressStart()
        this.phoneData = await this.checkPhoneNumber(contact.phone)
        // this.phoneData will be `false` if `this.phone.value` is not connected
        if (this.phoneData) this.$set(this.phoneData, 'phone', formatPhoneNumber(contact.phone))
        if (!this.phoneData.phone_valid) {
          valid = false
          this.$set(this.connected, 'phone', false)
          this.progressFail()
        } else {
          this.$set(this.connected, 'phone', true)
        }
        this.progressFinish()
      }
      else {
        this.$set(this.valid, 'phone', true)
      }

      // Either phone or email required
      if (!contact.email && !contact.phone) {
        this.alertWarning('Email or phone must be present')
        valid = false
      }

      if (this.pagePOV === 'debtor') {
        if (!contact.role || !this.isValidContactRole(contact.role)) {
          this.$set(this.valid, 'role', false)
          valid = false
        } else {
          this.$set(this.valid, 'role', true)
        }
      }

      return valid
    },
  },
}
</script>

<style lang="sass">
@import '../../../../styles/user-profile.sass'
</style>