<template>
  <div
    v-if="userContacts"
    class="UserProfileContacts column"
  >
    <!-- OWNERS -->
    <template v-if="pagePOV === 'client'">
      <div class="column mb-30">
        <!-- CONTACTS HEADER -->
        <div class="row row--justify-between mb-13">
          <label class="fs-18 fw-med">Owners</label>
          <base-button
            @click="newOwner = true; newContact = false"
            class="UserProfileContacts__new-btn bg-blue fc-white"
          >
            Add Owner
          </base-button>
        </div>

        <hr class="Divider mb-25">

        <transition
          mode="out-in"
          name="Transition__opacity-fade"
        >
          <!-- CONTACTS - NEW -->
          <new-owner-contact
            v-if="newOwner"
            @cancel="newOwner = false"
            @created="getUserContacts(); newOwner = false"
          />
        </transition>

        <div
          class="column"
          key="contactBaseAndEdit"
        >
          <!-- INDIVIDUAL OWNERS -->
          <div
            v-for="(contact, index) in owners"
            :key="index"
            class="UserProfileContacts__contact column"
          >
            <owner-contact
              @deleted="getUserContacts"
              :contact="contact"
            />
          </div>
        </div>
      </div>
    </template>

    <!-- CONTACTS -->
    <template>
      <div class="column mb-30">
        <!-- CONTACTS HEADER -->
        <div class="row row--justify-between mb-13">
          <label class="fs-18 fw-med">Contacts</label>
          <base-button
            @click="newContact = true; newOwner = false"
            class="UserProfileContacts__new-btn bg-blue fc-white"
          >
            Add Contact
          </base-button>
        </div>

        <hr class="Divider mb-25">

        <transition
          mode="out-in"
          name="Transition__opacity-fade"
        >
          <!-- CONTACTS - NEW -->
          <new-contact
            v-if="newContact"
            @cancel="newContact = false"
            @created="getUserContacts(); newContact = false"
            :page-p-o-v="pagePOV"
          />
        </transition>

        <div
          class="column"
          key="contactBaseAndEdit"
        >
          <!-- INDIVIDUAL OWNERS -->
          <div
            v-for="(contact, index) in contacts"
            :key="index"
            class="UserProfileContacts__contact column"
          >
            <contact
              @deleted="getUserContacts"
              :contact="contact"
              :page-p-o-v="pagePOV"
            />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
// Helpers
import {
  ClientContact,
  DebtorContact
} from '../utils/api'
// Components
import BaseButton from './base-button.vue'
import Contact from '../views/client/profile/contacts/contact.vue'
import NewContact from '../views/client/profile/contacts/new-contact.vue'
import NewOwnerContact from '../views/client/profile/contacts/new-owner-contact.vue'
import OwnerContact from '../views/client/profile/contacts/owner-contact.vue'

export default {
  name: 'UserProfileContacts',

  components: {
    BaseButton,
    Contact,
    NewContact,
    NewOwnerContact,
    OwnerContact,
  },

  props: {
    pagePOV: {
      type: String,
      required: true,
    },
  },

  created () {
    this.getUserContacts()
  },

  data () {
    return {
      contactInQuestion: null,
      contactToRemove: null,
      newContact: false,
      newOwner: false,
      userContacts: null,
    }
  },

  computed: {
    contacts () {
      if (this.userContacts === null) return
      return this.userContacts
        .filter(a => !a.client_owner)
        .sort((a, b) => a.is_primary > b.is_primary)
    },

    owners () {
      if (this.userContacts === null) return
      return this.userContacts
        .filter(a => a.client_owner)
        .sort((a, b) => a.equity_stake_percentage > b.equity_stake_percentage)
    },

    user () {
      if (this.pagePOV === 'client') {
        return this.$store.getters.client
      }
      return this.$store.getters.debtor
    }
  },

  methods: {
    async getUserContacts () {
      if (this.pagePOV === 'client') {
        try {
          this.userContacts = (await ClientContact.queryList({
            client_id: this.user.id
          })).data.rows
        }
        catch (error) {
          this.captureSentryEvent(
            'User Contact "Get Client Contacts" Error',
            {
              config: error.config,
              data: this.$data,
              details: error,
              props: this.$props,
              response: error.response,
            }
          )
          this.CError(error)
          this.requestFailure({ message: 'There was an issue getting the client\'s contacts' })
        }
      }
      if (this.pagePOV === 'debtor') {
        try {
          this.userContacts = (await DebtorContact.queryList({
            debtor_id: this.user.id
          })).data.rows
        }
        catch (error) {
          this.captureSentryEvent(
            'User Contacts "Get Debtor Contacts" Error',
            {
              config: error.config,
              data: this.$data,
              details: error,
              props: this.$props,
              response: error.response,
            }
          )
          this.CError(error)
          this.requestFailure({ message: 'There was an issue getting the debtor\'s contacts' })
        }
      }
    },
  },
}
</script>

<style lang="sass">
@import '../styles/user-profile.sass'
</style>