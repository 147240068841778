var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "OwnerContact width-100" },
    [
      _c(
        "transition",
        { attrs: { mode: "out-in", name: "Transition__opacity-fade" } },
        [
          !_vm.editing
            ? _c("div", { key: "contact", staticClass: "column" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "row row--align-center mb-4" }, [
                    _c("label", { staticClass: "fs-16 fw-med capitalize" }, [
                      _vm._v(_vm._s(_vm.contactDefault.name))
                    ]),
                    _vm.contactDefault.client_owner.equity_stake_percentage > 0
                      ? _c(
                          "label",
                          { staticClass: "fc-light fs-12 fw-med ml-8" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  Number(
                                    _vm.contactDefault.client_owner
                                      .equity_stake_percentage
                                  ).toFixed(2)
                                ) +
                                "%\n          "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm.contactDefault.is_primary
                      ? _c(
                          "label",
                          {
                            staticClass:
                              "UserProfileContacts__flag-lbl fs-12 uppercase bg-green ml-8"
                          },
                          [_vm._v("\n            PRIMARY CONTACT\n          ")]
                        )
                      : _vm._e()
                  ]),
                  _c("div", { staticClass: "row row--justify-end" }, [
                    _c(
                      "button",
                      {
                        staticClass: "fc-light fs-14 mr-25",
                        on: { click: _vm.editContact }
                      },
                      [
                        _c("i", { staticClass: "fa fa-edit fc-light" }),
                        _vm._v("\n            Edit\n          ")
                      ]
                    ),
                    _c(
                      "button",
                      {
                        class: [
                          "fs-14",
                          {
                            "fc-blue": _vm.contactDefault.send_notifications,
                            "fc-light": !_vm.contactDefault.send_notifications
                          }
                        ],
                        on: { click: _vm.toggleContactNotifications }
                      },
                      [
                        _c("i", {
                          class: [
                            "fa fa-bell",
                            {
                              "fc-blue": _vm.contactDefault.send_notifications,
                              "fc-light": !_vm.contactDefault.send_notifications
                            }
                          ]
                        }),
                        _vm._v(
                          "\n            Notifications " +
                            _vm._s(
                              _vm.contactDefault.send_notifications
                                ? "On"
                                : "Off"
                            ) +
                            "\n          "
                        )
                      ]
                    )
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "row row--justify-between row--align-end" },
                  [
                    _c("div", { staticClass: "column" }, [
                      _c(
                        "a",
                        {
                          staticClass: "mb-4",
                          attrs: {
                            href:
                              _vm.contactDefault.client_owner
                                .driver_license_url,
                            target: "_blank",
                            title: "View the contact's drivers license"
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-license fc-light fa--none"
                          }),
                          _c("span", { staticClass: "fc-light fs-14 ml-7" }, [
                            _vm._v("Drivers License")
                          ])
                        ]
                      ),
                      _c("label", { staticClass: "fc-light fs-14 mb-2" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.contactDefault.client_owner.address) +
                            _vm._s(
                              _vm.contactDefault.client_owner.address_2
                                ? " " +
                                    _vm.contactDefault.client_owner.address_2 +
                                    ","
                                : ","
                            ) +
                            "\n            " +
                            _vm._s(_vm.contactDefault.client_owner.city + ",") +
                            " " +
                            _vm._s(_vm.contactDefault.client_owner.state) +
                            "\n            " +
                            _vm._s(_vm.contactDefault.client_owner.zip) +
                            "\n          "
                        )
                      ]),
                      _c("label", { staticClass: "fc-light fs-14 mb-2" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.contactDefault.email) +
                            "\n            " +
                            _vm._s(
                              _vm.contactDefault.email &&
                                _vm.contactDefault.phone
                                ? "|"
                                : null
                            ) +
                            "\n            " +
                            _vm._s(_vm.contactDefault.phone) +
                            "\n          "
                        )
                      ])
                    ])
                  ]
                ),
                _c("div", { staticClass: "row" }, [
                  _c("label", { staticClass: "fc-light fs-14 mb-2" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm._f("datetime")(
                            _vm.contactDefault.client_owner.date_of_birth,
                            "MM/DD/YYYY"
                          )
                        ) +
                        "\n        "
                    )
                  ])
                ])
              ])
            : _c("div", { key: "contactEdit", staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "UserProfileContacts__owner-docs-wrapper column"
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass:
                          "BaseInvoiceCreate__section-lbl fs-14 fw-med"
                      },
                      [_vm._v("\n          DRIVERS LICENSE\n        ")]
                    ),
                    _vm.contactToEdit.client_owner.driver_license_url
                      ? _c("base-uploaded-item", {
                          attrs: {
                            index: 0,
                            filename: "driver_license",
                            url:
                              _vm.contactToEdit.client_owner.driver_license_url
                          },
                          on: {
                            "remove-upload": function($event) {
                              return _vm.$set(
                                _vm.contactToEdit.client_owner,
                                "driver_license_url",
                                null
                              )
                            }
                          }
                        })
                      : _vm._e(),
                    _c(
                      "base-button",
                      {
                        staticClass: "bg-green fc-white width-100",
                        on: { click: _vm.uploadId }
                      },
                      [_vm._v("\n          Upload Photo\n        ")]
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "column ml-24" }, [
                  _c(
                    "div",
                    { staticClass: "row row--wrap" },
                    [
                      _c(
                        "base-input",
                        {
                          staticClass: "mr-6 mb-9",
                          attrs: {
                            instructions:
                              _vm.instructions.SHORTENED_NAME_INSTRUCTION,
                            label: true,
                            "label-for": "name",
                            placeholder: "John Smith",
                            valid: _vm.valid.name
                          },
                          model: {
                            value: _vm.contactToEdit.name,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.contactToEdit,
                                "name",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "contactToEdit.name"
                          }
                        },
                        [_vm._v("\n            Name\n          ")]
                      ),
                      _c(
                        "base-input",
                        {
                          staticClass: "mr-6 mb-9",
                          attrs: {
                            instructions: _vm.instructions.EMAIL_INSTRUCTION,
                            label: true,
                            "label-for": "email",
                            placeholder: "ted@gmail.com",
                            valid: _vm.valid.email
                          },
                          model: {
                            value: _vm.contactToEdit.email,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.contactToEdit,
                                "email",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "contactToEdit.email"
                          }
                        },
                        [_vm._v("\n            Email\n          ")]
                      ),
                      _c(
                        "base-input",
                        {
                          staticClass: "mr-6 mb-9",
                          attrs: {
                            instructions: _vm.valid.phone
                              ? _vm.instructions.PHONE_CONNECTED_INSTRUCTION
                              : _vm.instructions.PHONE_INSTRUCTION,
                            label: true,
                            "label-for": "phone",
                            placeholder: "(213)361-3826",
                            valid: _vm.valid.phone && _vm.connected.phone
                          },
                          model: {
                            value: _vm.contactToEdit.phone,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.contactToEdit,
                                "phone",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "contactToEdit.phone"
                          }
                        },
                        [_vm._v("\n            Phone\n          ")]
                      ),
                      _c(
                        "base-input",
                        {
                          staticClass: "mr-6 mb-9",
                          attrs: {
                            instructions: _vm.instructions.ADDRESS_INSTRUCTION,
                            label: true,
                            "label-for": "address",
                            placeholder: "7246 Woodland Rd",
                            valid: _vm.valid.address
                          },
                          model: {
                            value: _vm.contactToEdit.client_owner.address,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.contactToEdit.client_owner,
                                "address",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "contactToEdit.client_owner.address"
                          }
                        },
                        [_vm._v("\n            Address\n          ")]
                      ),
                      _c(
                        "base-input",
                        {
                          staticClass: "mr-6 mb-9",
                          attrs: {
                            instructions:
                              _vm.instructions.ADDRESS_2_INSTRUCTION,
                            label: true,
                            "label-for": "address_2",
                            placeholder: "Apt 205",
                            valid: _vm.valid.address_2
                          },
                          model: {
                            value: _vm.contactToEdit.client_owner.address_2,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.contactToEdit.client_owner,
                                "address_2",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "contactToEdit.client_owner.address_2"
                          }
                        },
                        [_vm._v("\n            Address 2\n          ")]
                      ),
                      _c(
                        "base-input",
                        {
                          staticClass: "mr-6 mb-9",
                          attrs: {
                            instructions: _vm.instructions.CITY_INSTRUCTION,
                            label: true,
                            "label-for": "city",
                            placeholder: "Salt Lake City",
                            valid: _vm.valid.city
                          },
                          model: {
                            value: _vm.contactToEdit.client_owner.city,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.contactToEdit.client_owner,
                                "city",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "contactToEdit.client_owner.city"
                          }
                        },
                        [_vm._v("\n            City\n          ")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "OwnerContact__select-container column mb-12 mr-6"
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "BaseInput__label uppercase",
                              attrs: { for: "state" }
                            },
                            [_vm._v("\n              State\n            ")]
                          ),
                          _c("v-select", {
                            class: [
                              "OwnerContact__select",
                              {
                                "OwnerContact__select--invalid": !_vm.valid
                                  .state
                              }
                            ],
                            attrs: {
                              "aria-label": "Select input to choose state",
                              id: "state",
                              clearable: false,
                              instructions: _vm.instructions.STATE_INSTRUCTION,
                              options: _vm.options.state,
                              placeholder: "State:"
                            },
                            model: {
                              value: _vm.contactToEdit.client_owner.state,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.contactToEdit.client_owner,
                                  "state",
                                  $$v
                                )
                              },
                              expression: "contactToEdit.client_owner.state"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "base-input",
                        {
                          staticClass: "mr-6 mb-9",
                          attrs: {
                            instructions: _vm.instructions.ZIP_INSTRUCTION,
                            label: true,
                            "label-for": "zip",
                            placeholder: "89009",
                            valid: _vm.valid.zip
                          },
                          model: {
                            value: _vm.contactToEdit.client_owner.zip,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.contactToEdit.client_owner,
                                "zip",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "contactToEdit.client_owner.zip"
                          }
                        },
                        [_vm._v("\n            Zip\n          ")]
                      ),
                      _c(
                        "base-input",
                        {
                          staticClass: "mr-6 mb-9",
                          attrs: {
                            instructions: _vm.instructions.EQUITY_INSTRUCTION,
                            label: true,
                            "label-for": _vm.instructions.EQUITY_INSTRUCTION,
                            placeholder: "50",
                            valid: _vm.valid.equity_stake_percentage
                          },
                          model: {
                            value:
                              _vm.contactToEdit.client_owner
                                .equity_stake_percentage,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.contactToEdit.client_owner,
                                "equity_stake_percentage",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression:
                              "contactToEdit.client_owner.equity_stake_percentage"
                          }
                        },
                        [
                          _vm._v(
                            "\n            Equity stake percentage\n          "
                          )
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "column column--width-auto" },
                        [
                          _c(
                            "label",
                            { staticClass: "fc-light fs-12 mb-7 uppercase" },
                            [
                              _vm._v(
                                "\n              Date of Birth\n            "
                              )
                            ]
                          ),
                          _c("base-date-picker", {
                            staticClass: "mr-6 mb-9",
                            attrs: {
                              instruction:
                                _vm.instructions.DATE_OF_BIRTH_INSTRUCTION,
                              placeholder: "8/31/83",
                              valid: _vm.valid.date_of_birth
                            },
                            model: {
                              value:
                                _vm.contactToEdit.client_owner.date_of_birth,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.contactToEdit.client_owner,
                                  "date_of_birth",
                                  $$v
                                )
                              },
                              expression:
                                "contactToEdit.client_owner.date_of_birth"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "base-checkbox",
                        {
                          staticClass: "ml-12 mr-6 mt-25",
                          attrs: {
                            "starting-value": _vm.contactToEdit.is_primary
                          },
                          model: {
                            value: _vm.contactToEdit.is_primary,
                            callback: function($$v) {
                              _vm.$set(_vm.contactToEdit, "is_primary", $$v)
                            },
                            expression: "contactToEdit.is_primary"
                          }
                        },
                        [_vm._v("\n            Primary Contact\n          ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "row row--align-center" },
                    [
                      _c(
                        "base-button",
                        {
                          staticClass: "bg-blue fc-white",
                          on: { click: _vm.updateOwner }
                        },
                        [_vm._v("\n            Save Contact\n          ")]
                      ),
                      _c(
                        "base-button",
                        {
                          staticClass: "bg-light fc-white ml-6",
                          on: {
                            click: function($event) {
                              _vm.editing = false
                            }
                          }
                        },
                        [_vm._v("\n            Cancel\n          ")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "fc-light fs-14 ml-12",
                          on: {
                            click: function($event) {
                              _vm.deleting = true
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fa fa-trash-can" }),
                          _vm._v("\n            Delete Contact\n          ")
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
        ]
      ),
      _c("transition", { attrs: { name: "Transition__opacity-fade" } }, [
        _vm.deleting
          ? _c("div", { staticClass: "UserProfileContacts__modal" }, [
              _c("div", { staticClass: "UserProfileContacts__modal-bg" }),
              _c(
                "div",
                { staticClass: "UserProfileContacts__modal-content column" },
                [
                  _c("label", { staticClass: "fs-20 fw-med mb-21" }, [
                    _vm._v(
                      "\n          Are you sure you would like to delete this contact?\n        "
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "row mb-21" },
                    [
                      _c(
                        "base-button",
                        {
                          staticClass:
                            "UserProfileContacts__modal-btn bg-blue fc-white mr-10",
                          on: { click: _vm.removeOwner }
                        },
                        [
                          _vm._v(
                            "\n            Yes, Delete Contact\n          "
                          )
                        ]
                      ),
                      _c(
                        "base-button",
                        {
                          staticClass:
                            "UserProfileContacts__modal-btn bg-light fc-white",
                          on: {
                            click: function($event) {
                              _vm.deleting = false
                            }
                          }
                        },
                        [_vm._v("\n            No\n          ")]
                      )
                    ],
                    1
                  )
                ]
              )
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }